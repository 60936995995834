import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import Btn from "@/components/Button/Btn.vue";
let LandingPage = class LandingPage extends Vue {
    mounted() { }
};
LandingPage = __decorate([
    Component({
        components: {
            Btn,
        },
        metaInfo() {
            return {
                title: "Africa Geographic",
                meta: [
                    {
                        name: "description",
                        content: "Africa Geographic",
                    },
                ],
                link: [{ rel: "canonical", href: `${process.env.VUE_APP_URL}/` }],
            };
        },
    })
], LandingPage);
export default LandingPage;
